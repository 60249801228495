<template>
   <div class="Container-fluid">
     <template v-if="isRequestInProgress">
       <loading></loading>
     </template>
     <template v-else>
      <div class="row mb-2 mt-4">
        <div class="col-md-3">
          <h1>
            {{ $t("mainMenu.dashboard") }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div>
          <h2>
            <em class="bi bi-calculator-fill"></em>
            {{ $t('dashboard.commons.title') }}
          </h2>
          <div class="row white-box">
            <div class="col-lg-6">
              <div class="white-box">
                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">{{
                    $t("dashboard.commons.revenue")
                  }}
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ '₡' }}{{ dashboardInfo.generalData.totalRevenue | currency }}</div>
              </div>

              <opportunities-summary :title="'dashboard.commons.opportunities.onlineTitle'"
                                     :opportunities-summary="dashboardInfo.generalData.allOpportunitiesSummary">
              </opportunities-summary>

              <opportunities-summary :title="'dashboard.commons.opportunities.paidWithPlanTitle'"
                                     :opportunities-summary="dashboardInfo.generalData.inPlanPaidOpportunitiesSummary">
              </opportunities-summary>

              <opportunities-summary :title="'dashboard.commons.opportunities.paidIndividuallyPlanTitle'"
                                     :opportunities-summary="dashboardInfo.generalData.individuallyPaidOpportunitiesSummary">
              </opportunities-summary>

              <users-summary :users-summary="dashboardInfo.generalData.usersSummary"></users-summary>

            </div>
            <div class="col-lg-6">

              <plans-summary :plans-summary="dashboardInfo.generalData.plansSummary"></plans-summary>

              <renewals-summary :renewing-summary="dashboardInfo.generalData.renewingSummary"></renewals-summary>

              <div class="white-box">
                <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                  {{ $t("dashboard.commons.reported.title") }}
                </div>
                <p>
                  {{ $t("dashboard.commons.reported.subTitle") }}
                  <a href="#" id="check-reported" @click.prevent="goToReportedOpportunities()">{{
                      dashboardInfo.generalData.totalReportedOpportunities
                    }}</a>
                </p>
              </div>
              <div class="white-box">
                <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                  {{ $t("dashboard.purchases.title") }}
                </div>
                <p>
                  <a href="#" id="link-general-purchases-per-user" @click.prevent="generalUsersPurchases()">{{ $t("dashboard.purchases.seeList") }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="row">
        <div>
          <h2>
            <em class="bi bi-calculator-fill"></em>
            {{ $t('dashboard.period.title') }}
          </h2>
          <div class="row">
            <div class="white-box">
              <div class="row">
                <div class="col">
                  <div class="row justify-content-lg-end g-3">
                    <div class="col-auto">
                      <input type="date" class="form-control" v-model="startDate"/>
                    </div>
                    <div class="col-auto">
                      <input type="date" class="form-control" v-model="endDate"/>
                    </div>
                    <div class="col-auto">
                      <button
                          type="button"
                          @click="loadDashboardData()"
                          class="btn btn-primary"
                      >
                        {{ $t("commons.request") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="white-box">
                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                      {{ $t("dashboard.period.revenue") }}
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">₡{{ dashboardInfo.periodData.totalRevenue }}</div>
                  </div>

                  <opportunities-summary :title="'dashboard.commons.opportunities.onlineTitle'"
                                         :opportunities-summary="dashboardInfo.periodData.opportunitiesSummary">
                  </opportunities-summary>

                  <opportunities-summary :title="'dashboard.commons.opportunities.paidWithPlanTitle'"
                                         :opportunities-summary="dashboardInfo.periodData.inPlanPaidOpportunitiesSummary">
                  </opportunities-summary>

                  <opportunities-summary :title="'dashboard.commons.opportunities.paidIndividuallyPlanTitle'"
                                         :opportunities-summary="dashboardInfo.periodData.paidIndividuallyPlanTitle">
                  </opportunities-summary>

                </div>
                <div class="col-lg-6">

                  <plans-summary :plans-summary="dashboardInfo.periodData.plansSummary"></plans-summary>

                  <users-summary :users-summary="dashboardInfo.periodData.usersSummary"></users-summary>

                  <div class="white-box">
                    <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                      {{ $t("dashboard.purchases.title") }}
                    </div>
                    <p>
                      <a href="#" id="link-period-purchases-per-user" @click.prevent="periodUsersPurchases()">{{ $t("dashboard.purchases.seeList") }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <h3>
                <em class="bi bi-graph-up"></em>
                {{ $t("dashboard.opportunitiesInPeriodChart") }}
              </h3>
              <div class="white-box">
                <opportunities-diagram
                    :dashboard="dashboardInfo"
                ></opportunities-diagram>
              </div>
            </div>
          </div>
        </div>
      </div>
     </template>
  </div>
</template>
<script>
import {ApiService} from "@/rest";
import {DashboardInfo} from "@/entities";
import {formatEndingDateTime, formatStartingDateTime, fromDatePickerToDate, getOneMonthAgo, getToday} from "@/helper";
import OpportunitiesDiagram from "@/components/administration/dashboard/OpportunitiesDiagram.vue";
import OpportunitiesSummary from "@/components/administration/dashboard/OpportunitiesSummary.vue";
import UsersSummary from "@/components/administration/dashboard/UsersSummary.vue";
import RenewalsSummary from "@/components/administration/dashboard/RenewalsSummary.vue";
import TotalEntry from "@/components/administration/dashboard/TotalEntry.vue";
import PlansSummary from "@/components/administration/dashboard/PlansSummary";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: "Dashboard",
  components: {
    PlansSummary,
    OpportunitiesDiagram,
    OpportunitiesSummary,
    UsersSummary,
    RenewalsSummary,
    TotalEntry,
    Loading
  },
  data() {
    return {
      dashboardInfo: new DashboardInfo(),
      startDate: getOneMonthAgo(),
      endDate: getToday(),
      loaded: false,
      isRequestInProgress: false
    };
  },
  methods: {
    loadDashboardData() {
      this.isRequestInProgress = true;
      ApiService.GET(
          "/dashboard/protected/info/" +
          formatStartingDateTime(this.startDate) +
          "/" +
          formatEndingDateTime(this.endDate),
          (err, apiResponse) => {
            this.isRequestInProgress = false;
            if (!err) {
              this.dashboardInfo = apiResponse;
              this.loaded = true;
            }
          }
      );
    },
    goToReportedOpportunities() {
      this.$router.push("/reportedOpportunities");
    },
    generalUsersPurchases() {
      const veryLongAgo  = new Date(1970, 2, 2, 12);

      this.loadUsersPurchases(veryLongAgo, new Date());
    },
    periodUsersPurchases() {
      this.loadUsersPurchases(fromDatePickerToDate(this.startDate), fromDatePickerToDate(this.endDate));
    },
    loadUsersPurchases(startDate, endDate) {
      this.$router.push({
        name: 'userPurchases',
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      })
    }
  },
  created() {
    this.loadDashboardData();
  }
};
</script>
