<template>
  <div class="container">
    <dashboard/>
  </div>
</template>

<script>
// @ is an alias to /src
import Dashboard from '@/components/administration/dashboard/Dashboard.vue'

export default {
  name: 'AdminDashboard',
  components: {
    Dashboard
  }
}
</script>
