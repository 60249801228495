<template>
<div class="white-box">
  <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
    {{ $t(title) }}
  </div>
  <div class="font-weight-bold text-gray-800">
    {{ $t("commons.total") }}
    {{ opportunitiesSummary.totalCreated }}
  </div>
  <total-entry
      class="ms-3"
      :label="$t('dashboard.commons.opportunities.gold')"
      :amount="opportunitiesSummary.totalGoldLevel"
      :total="opportunitiesSummary.totalPaid"
  />
  <total-entry
      class="ms-3"
      :label="$t('dashboard.commons.opportunities.silver')"
      :amount="opportunitiesSummary.totalSilverLevel"
      :total="opportunitiesSummary.totalPaid"
  />
  <total-entry
      class="ms-3"
      :label="$t('dashboard.commons.opportunities.bronze')"
      :amount="opportunitiesSummary.totalBronzeLevel"
      :total="opportunitiesSummary.totalPaid"
  />

  <total-entry
      class="ms-3"
      :label="$t('dashboard.commons.opportunities.free')"
      :amount="opportunitiesSummary.totalFreeLevel"
  />
</div>

</template>
<script>
import {OpportunitiesSummary} from "@/entities";
import TotalEntry from "@/components/administration/dashboard/TotalEntry.vue";

export default {
  name: "OpportunitiesSummary",
  components: {
    TotalEntry
  },
  props: {
    opportunitiesSummary: {
      type: Object,
      default: () => new OpportunitiesSummary()
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
