<template>
  <div class="white-box">
    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
      {{ $t("user.userRegister") }}
    </div>
    <total-entry
        :label="$t('dashboard.commons.users.total')"
        :amount="usersSummary.totalCreated"
    />
    <total-entry
        class="ms-2"
        :label="$t('dashboard.commons.users.organic')"
        :amount="usersSummary.organic"
        :total="usersSummary.totalCreated"
    />
    <impersonal-summary
        :impersonal-summary="usersSummary.impersonalSummary"
    ></impersonal-summary>
  </div>
</template>
<script>
import {UsersSummary} from "@/entities";
import TotalEntry from "@/components/administration/dashboard/TotalEntry.vue";
import ImpersonalSummary from "@/components/administration/dashboard/ImpersonalSummary.vue";

export default {
  name: "UsersSummary",
  components: {
    TotalEntry,
    ImpersonalSummary
  },
  props: {
    usersSummary: {
      type: Object,
      default: () => new UsersSummary()
    }
  }
};
</script>
