<template>
<div class="white-box">
  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
    {{ $t("dashboard.commons.plans.title") }}
  </div>
  <total-entry
      :label="$t('dashboard.commons.plans.total')"
      :amount="plansSummary.paidPlans.totalCreated"
  />
  <total-entry
      class="ms-2"
      :label="$t('dashboard.commons.opportunities.gold')"
      :amount="plansSummary.paidPlans.totalGoldLevel"
      :total="plansSummary.paidPlans.totalCreated"
  />
  <total-entry
      class="ms-2"
      :label="$t('dashboard.commons.opportunities.silver')"
      :amount="plansSummary.paidPlans.totalSilverLevel"
      :total="plansSummary.paidPlans.totalCreated"
  />
  <total-entry
      class="ms-2"
      :label="$t('dashboard.commons.opportunities.bronze')"
      :amount="plansSummary.paidPlans.totalBronzeLevel"
      :total="plansSummary.paidPlans.totalCreated"
  />
  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
    {{ $t("dashboard.commons.plans.sponsoredPlans") }}
  </div>
  <total-entry
      :label="$t('dashboard.commons.plans.total')"
      :amount="plansSummary.sponsoredPlans.totalCreated"
  />
  <total-entry
      class="ms-2"
      :label="$t('dashboard.commons.opportunities.gold')"
      :amount="plansSummary.sponsoredPlans.totalGoldLevel"
      :total="plansSummary.sponsoredPlans.totalCreated"
  />
  <total-entry
      class="ms-2"
      :label="$t('dashboard.commons.opportunities.silver')"
      :amount="plansSummary.sponsoredPlans.totalSilverLevel"
      :total="plansSummary.sponsoredPlans.totalCreated"
  />
  <total-entry
      class="ms-2"
      :label="$t('dashboard.commons.opportunities.bronze')"
      :amount="plansSummary.sponsoredPlans.totalBronzeLevel"
      :total="plansSummary.sponsoredPlans.totalCreated"
  />
</div>

</template>
<script>
import {PlansSummary} from "@/entities";
import TotalEntry from "@/components/administration/dashboard/TotalEntry.vue";

export default {
  name: "PlansSummary",
  components: {
    TotalEntry
  },
  props: {
    plansSummary: {
      type: Object,
      default: () => new PlansSummary()
    }
  }
};
</script>
