<template>
  <div class="font-weight-bold text-gray-800">
    {{ label }}:
    {{ formattedAmount }}
    {{ percent }}
  </div>
</template>
<script>
import { formatAmount} from "@/helper";
export default {
  name: "TotalEntry",
  props: {
    label: {
      type: String,
      default: ""
    },
    amount: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    formattedAmount() {
      return formatAmount(this.amount);
    },
    percent() {
      if (this.total) {
        if (this.amount || this.amount === 0) {
          const percent = this.amount / this.total * 100;

          return `(${percent.toFixed(1)}%)`;
        }

        return "(__._ %)";
      }

      return "";
    }
  }
};
</script>
