<template>
  <div class="white-box">
    <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
      {{ $t("dashboard.commons.renewals.title") }}
    </div>
    <total-entry
        :label="$t('dashboard.commons.renewals.pending')"
        :amount="renewingSummary.pending"
    />
    <total-entry
        :label="$t('dashboard.commons.renewals.expired')"
        :amount="renewingSummary.expired"
    />
  </div>
</template>
<script>
import {RenewingSummary} from "@/entities";
import TotalEntry from "@/components/administration/dashboard/TotalEntry.vue";

export default {
  name: "RenewalsSummary",
  components: {
    TotalEntry
  },
  props: {
    renewingSummary: {
      type: Object,
      default: () => new RenewingSummary()
    }
  }
};
</script>
