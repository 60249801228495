<template>
  <div>
    <total-entry
        :label="$t('dashboard.commons.users.impersonalized.total')"
        :amount="impersonalSummary.totalRegistered"
    />
    <total-entry
        class="ms-2"
        :label="$t('dashboard.commons.users.impersonalized.confirmed')"
        :amount="impersonalSummary.totalConfirmed"
        :total="impersonalSummary.totalRegistered"
    />
    <total-entry
        class="ms-2"
        :label="$t('dashboard.commons.users.impersonalized.notConfirmed')"
        :amount="impersonalSummary.totalNoConfirmed"
        :total="impersonalSummary.totalRegistered"
    />
  </div>
</template>
<script>
import {ImpersonalSummary} from "@/entities";
import TotalEntry from "@/components/administration/dashboard/TotalEntry.vue";

export default {
  name: "ImpersonalSummaryComp",
  components: {
    TotalEntry
  },
  props: {
    impersonalSummary: {
      type: Object,
      default: () => new ImpersonalSummary()
    }
  }
};
</script>
