<template>
  <div>
    <canvas ref="newVsPay" width="400" height="100"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
import { ChartDataset } from "@/entities";

export default {
  name: "OpportunitiesDiagram",
  props: {
    dashboard: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    dashboard: function() {
      this.loadCharts();
    }
  },
  methods: {
    loadCharts() {
      if (this.chart) {
        this.chart.destroy();
      }

      const chartEntries = new Map();

      this.dashboard.periodData.chartSeries.paid.data.forEach((count, i) => {
        chartEntries.set(this.dashboard.periodData.chartSeries.paid.labels[i], [
          count,
          0
        ]);
      });

      this.dashboard.periodData.chartSeries.free.data.forEach((count, i) => {
        const label = this.dashboard.periodData.chartSeries.free.labels[i];
        const existingEntry = chartEntries.get(label);

        if (existingEntry) {
          existingEntry[1] = count;
        } else {
          chartEntries.set(label, [0, count]);
        }
      });

      const triplets = [];

      chartEntries.forEach((tuple, label) => {
        triplets.push([label, ...tuple]);
      });

      triplets.sort((a, b) => b[0].localeCompare(a[0]));

      const ctx = this.$refs.newVsPay.getContext("2d");
      const cfg = this.chartConfig(
        triplets.map(t => t[1]),
        triplets.map(t => t[2]),
        triplets.map(t => t[0]).map(x => new Date(x))
      );

      this.chart = new Chart(ctx, cfg);
    },
    chartConfig(payedOpps, freeOpps, labels) {
      return {
        data: {
          labels,
          datasets: [
            new ChartDataset({
              label: this.$i18n.t("dashboard.diagram.payedOpportunities"),
              backgroundColor: "#0273b0",
              borderColor: "#0273b0",
              data: payedOpps
            }),
            new ChartDataset({
              label: this.$i18n.t("dashboard.diagram.freeOpportunities"),
              backgroundColor: "orange",
              borderColor: "orange",
              data: freeOpps
            })
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  displayFormats: {
                    day: "D MMM YYYY"
                  },
                  unit: "day"
                },
                distribution: "series",
                ticks: {
                  source: "labels"
                }
              }
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t("dashboard.diagram.leftLabel")
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      };
    }
  },
  mounted() {
    this.loadCharts();
  }
};
</script>
